import 'dayjs/locale/en-ie';
import dsTranslations from '@loveholidays/design-system/lib/translations/en-GB.json';
import dayjs from 'dayjs';

dayjs.locale('en-ie');

import translations from './main.json';

export const namespaces = {
  '': translations,
  lvhds: dsTranslations,
};
